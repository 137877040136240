.masterDataStyle{
  & .dragged {
    position: absolute;
    top: 0;
    opacity: .5;
    z-index: 2000;
  }

  & .placeholder {
    height: 110px;
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    background-color: #00000055 !important;
    width: 100%;

    &:before {
      position: absolute;
      content: " ";
      width: 0;
      height: 0;
      margin-top: -5px;
      left: -5px;
      top: -4px;
      // border: 5px solid transparent;
      // border-right: none;
    }
  }

  & table {
    & #cursor_move {
      cursor: move;
      width: 60px;
      text-align: center;
    }
    & .add_new {
      & #cursor_move {
        cursor: default;
        & .cursor_move {
          display: none;
        }
      }
    }
    & #imgpre {
      width: 10%;

      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        & img {
          max-height: 60px;
          max-width: 100%;
        }
      }
    }

    & #browse {
      width: 35%;

      & button {
        width: 30%;
        height: 45px;
        border-radius: 5px;
        border: 1px solid;
        color: $main_color;
        font-size: 13px;
        background-color: transparent;
        z-index: 0;
      }

      & input {
        margin-bottom: 0px;
        margin-left: 20px;
        width: 50%;
      }
    }
    & #tool,
    & #priority {
      & div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        & a {
          color: black;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          border-radius: 30px;

          &:hover {
            background-color: #e6e6e6;
          }
        }
      }
    }
  }
}