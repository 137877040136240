#menu-top-project {
    .row .col.text {
        width: 10%;
        color: #333;
        margin-left: auto;
        left: auto;
        right: auto;
        display: flex;
        justify-content: center;
    }
    .row .col.icon {
        width: 5%;
        color: #333;
        margin-left: auto;
        left: auto;
        right: auto;
        cursor: default;
    }
    .custom-tabs {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        height: 48px;
        width: 100%;
        background-color: $theme-color;
        margin: 0 auto;
        white-space: nowrap;
    }
    .custom-tabs .tab a.active {
        background-color: transparent;
        border-bottom: 4px solid #039be5;
    }
    .custom-tabs .tab a {
        background-color: transparent;
        color: #777;
        display: block;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-transition: color .28s ease;
        transition: color .28s ease;
    }
    .custom-tabs .tab {
        display: inline-block;
        text-align: center;
        line-height: 48px;
        height: 48px;
        padding: 0;
        margin: 0;
        text-transform: none;
    }
    .straight {
        color: #fff !important;
        display: inline-block;
        font-size: 70px !important;
        transform: scale(.4, 1) !important;
    }
    .proj_name {
        background-color: $theme-color;
        padding: 20px;
    }
    .proj_name a {
        color: #fff;
    }
    .tiny {
        color: #fff;
        position: absolute;
    }
}