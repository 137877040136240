#master-tag_keyword {
    .top-tool {
        display: flex;
        // padding: 25px;
        // text-align: right;
        // background: #e6e6e6;
        // margin-top: 15px;
        // margin-right: 20px;
        /* margin-left: 80%; */
        justify-content: space-evenly;
    }
    .btn-excel {
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 15vw;
        z-index: 100;
    }
    .Import_Export-data {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #fff !important;
        border: 1px solid #0033a1 !important;
        border-radius: 3px;
        color: #0033a1;
        padding: 5px 8px 5px 5px;
        a.btn-floating {
            background-color: $theme-color;
            margin-right: 5px;
        }
        a.text {
            color: $theme-color;
            font-size: $font-size-md;
        }
    }
    // ===== button tab =====
    .collection .collection-item.active {
        color: white;
        background-color: $theme-color;
        border: 1px solid $theme-color;
    }
    .collection {
        width: 100%;
        display: inline-block;
        border: 0px solid #fff;
    }
    .collection-item {
        float: left;
    }
    .collection .collection-item {
        border: 1px solid #000;
    }
    .collection a.collection-item {
        background-color: white;
        color: black;
        display: flex;
        justify-content: space-around;
    }
    .collection .collection-item:last-child {
        border-bottom: 1px solid black;
    }
    .collection a.collection-item span {
        margin-top: 10px;
    }
    .content-add {
        cursor: pointer;
        right: 0;
        bottom: 0;
        float: right;
        border: 1px solid #003097;
        background-color: #003097;
        color: #fff;
        border-radius: 5px;
        padding: 0 20px;
        width: 200px;
        padding-bottom: 15px;
    }
    .content-add p {
        display: flex;
        align-items: center;
    }
    .content-add img {
        width: 20px;
        margin-right: 10px;
    }
    .container {
        margin-top: 35px;
        /* width: 85%; */
        margin-bottom: 5%;
    }
    /* img{
		background-color: white;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    /* } */
    .hidedata {
        display: none;
    }
    th#tool a {
        color: black;
        cursor: pointer;
    }
    tr th {
        padding: 0px;
    }
    /* td#name{
		width: 17%;
		padding-left: 20px;
	} */
    td#area {
        width: 10%;
        padding-left: 20px;
    }
    td#imgpre {
        width: 15%;
        text-align: right;
    }
    td#browse {
        /*width: 50%;*/
        text-align: left;
    }
    td#tool {
        width: 19%;
        padding-right: 10px;
    }
    td#tool a:not(.discard-new) {
        color: black;
        // display: flex;
        justify-content: flex-end;
        margin-right: 10px;
    }
    td#tool ul li a {
        color: black;
        display: flex;
        border: 1px solid;
        font-size: 14px;
        margin-right: 0;
        justify-content: flex-start;
    }
    td#tool div {
        /* display: flex; */
        justify-content: center;
    }
    td#tool div a {
        /* color: #039be5; */
        cursor: pointer;
    }
    .priority {
        position: absolute;
    }
    .status {
        // margin-left: 70px;
        // float: right;
        display: flex;
    }
    #use_for {
        width: 130px;
    }
    td#browse button {
        width: 25%;
        height: 45px;
        border-radius: 5px;
        border: 1px solid;
        color: #0033a1;
        font-size: 13px;
        background-color: transparent;
        z-index: 0;
    }
    td#browse input {
        margin-bottom: 0px;
        margin-left: 20px;
        width: 60%;
    }
    .btn-floating {
        width: 28px;
        height: 28px;
        background-color: black;
    }
    .btn-floating i {
        font-size: 1.5rem;
        line-height: 30px;
    }
    .btn-floating:hover {
        background-color: #000;
    }
    /* table{
		margin: 35px 0 20px 0
	} */
    table.striped tbody tr {
        background-color: #fafafa;
    }
    table.striped tbody tr:nth-child(odd) {
        background-color: #ededed;
    }
    td .submit-container {
        position: inherit;
        background-color: transparent;
    }
    td .submit-container .btn-submit {
        color: #fff;
        background: #5a8eff;
        border: solid 1px #5a8eff;
    }
    td .submit-container a.btn-discard {
        color: #1641b5 !important;
        border: solid 1px #1641b5 !important;
        border-radius: 5px !important;
        padding: 8px 10px;
    }
    .submit-container .btn-submit {
        color: #fff;
    }
    .add-keyword {
        display: flex;
        align-items: center;
        flex-flow: column;
    }
    h5 {
        font-size: 1.4rem;
        font-weight: 600;
    }
    .tag_type_act_all {
        cursor: pointer;
    }
    thead {
        border-bottom: 0px solid #d0d0d0;
    }
    .tag_type_status {
        margin-left: 15px;
        /*z-index: 1px;*/
    }
    .placeholder {
        background-color: #F5F5F5;
        width: inherited;
    }
    #add_btn {
        margin: 0;
        text-align: center;
        /* position: fixed; */
        top: auto;
        left: auto;
        z-index: 1000;
        bottom: 75px;
        line-height: 0;
        right: 35px;
        /* color: #0033a1; */
        font-weight: 500;
    }
    #add_btn .btn-floating i {
        width: inherit;
        display: inline-block;
        text-align: center;
        color: #fff;
        font-size: 1.6rem;
        line-height: 30px;
    }
    #add_btn>a {
        background-color: #0033a1;
    }
    [type="checkbox"]+label {
        height: 0;
        line-height: 6px;
    }
    [type="radio"]:checked+label:after,
    [type="radio"].with-gap:checked+label:after {
        background-color: #0033a1;
    }
    [type="radio"]:checked+label:after,
    [type="radio"].with-gap:checked+label:before,
    [type="radio"].with-gap:checked+label:after {
        border: 2px solid #0033a1;
    }
    .btn,
    .btn-large {
        background-color: #0033a1;
    }
    // ===== button tab =====
}