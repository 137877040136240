#projectdetail-view {
	.content-head {
		font-size: $font-size-md;
		font-weight: 500;	
	}

	.content-sub-head {
		font-size: $font-size-lb;
		font-weight: 500;
	}


	// ===== button upload image =====
	button#ckfinder-modal-fact, button#ckfinder-modal-map {
		color: $theme-color;
		border: 1px solid $theme-color;
		background-color: transparent;
		padding: 10px 0;
		width: 100%;
		border-radius: 4px;
	}

	input#ckfinder-input-logo {
	    height: 2.8rem;
	}

	div#cancel {
	    height: 3rem;
	    line-height: 3rem;
	    a {
	    	color: #000;
	    	font-weight: 500;
	    }
	}

	img#ckfinder-img-preview-map {
	    width: 200px;
	    margin-top: 0;
	    max-width: 200px;
	    float: left;
	    max-height: 200px;
	}

	.img-guild-head, .img-guild-body {
		font-weight: 500;
	}

	.img-guild-head {
		font-size: $font-size-lb;
	}

	.img-guild-body {
		font-size: $font-size-sm;
		color: #9e9e9e;
	}

	
	.btn.btn-sync {
		color: $theme-color;
		border: 1px solid $theme-color;
		background-color: transparent;
		padding: 10px 0;
		width: 100%;
		border-radius: 4px;
		font-weight: bold;
		line-height: 1.4;
		height: unset;
	}
	.nearby_updatedate{
		margin-top: 10px;
		font-size: 12px;
	}
	.nearbyplace_zone {
		.nearbyitem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0;
			&:hover {
				// background-color: #ededed;
			}
			i {
				cursor: pointer;
			}
		}
	}

	#modal_edit_nearby {
		.modal-content {
			overflow-y: auto;
		}

		.result_nearbyplace {
			.row {
				display: flex;
				align-items: center;
			}
			.actions {
				display: flex;
				justify-content: space-evenly;
			}
			i {
				cursor: pointer;
			}
			
		}
	}


	// #browseBTN button{
	// 	/*margin-top: 30px;*/
	// 	width: 6vw;
	// 	height: 45px;
	// 	border-radius: 5px;
	// 	border: 1px solid;
	// 	color: #0e7ccb;
	// 	font-size: 13px;
	// 	background-color: transparent;
	// 	z-index: 0;
	// }
	// ===== button upload image =====

}