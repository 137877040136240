
  table.dataTable thead .sorting {
    background-image: url();
 }

 table.dataTable tbody th, table.dataTable tbody td {
   padding: 5px 5px;
 }
 table.dataTable tbody th a, table.dataTable tbody td a {
   color: #0033a1;
 }
 table.dataTable thead th, table.dataTable thead td {
   padding: 5px 5px;
 }
 .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
     color: #f2f2f2 !important;
     border: 0px solid #979797 !important;
     background: #0033a1 !important;
 }

 .dt-buttons{
   float: right;
   margin: 24px 0;
 }
 .dt-buttons button {
   background-color: #fff !important;
   border: 1px solid #0033a1 !important;
   border-radius: 3px;
   color: #0033a1;
 }

 .head-filter {
   background-color: #0033a1;
   padding: 2% 3% 2% 7%;
   color: #fff;
   font-size: 1.2vw;
   max-height: 60px;
   height:  60px;
   display:  flex;
   align-items:  center; 
   justify-content: space-between;
 }


 .modal_content{
   margin: 7% 7%;
   overflow: scroll;
 }
 .modal_content .title_label{
   font-weight: 800;
   font-size: 1vw;
   margin: 5px 0 ;
 }
 .modal_content .title_data{
   font-size: 0.9vw;
   margin: 0 0 5px 0 ;
 }
 #modal_filter-content [type="checkbox"]+label {
   height: 23px;
 }
 #modal_filter-content .arrowDropdown {
   right: 10%;
   position: absolute;
 }
 [type="checkbox"].filled-in:not(:checked)+label:after {
   height: 19px;
   width: 19px;
   top: 2px;
   border-radius: 4px;
 }
 #modal_filter-content .head-dev [type="checkbox"]+label,
 #modal_filter-content .head-proj [type="checkbox"]+label,
 #modal_filter-content .head-utm [type="checkbox"]+label {
   font-weight: 600;
   font-size: 16px;
   color: #000;
 }
 #modal_filter-content .list-dev [type="checkbox"]+label, 
 #modal_filter-content .list-proj [type="checkbox"]+label,
 #modal_filter-content .list-utm [type="checkbox"]+label {
   color: #000;
 }
 #modal_filter-content [type="checkbox"].filled-in:checked+label:after {
   border: 2px solid #0033a1;
   background-color: #0033a1;
   height: 19px;
   width: 19px;
   top: 2px;
   border-radius: 4px;
 }
 [type="checkbox"].filled-in:checked+label:before {
   top: 2px;
 }



 .input-field.col .dropdown-content [type="checkbox"]+label {
   top: -11px;
 }
 .select-wrapper input.select-dropdown{
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .header {
   background-color: #f2f2f2;
   padding: 10px 0 17px 0;
  //  color: #0033a1;
 }
 .filter_search {
   background-color: #fff;
   padding: 10px;
   height: 67px;
 }
 .close {
   width: 11px;
 }
 .filter {
   border: 1px solid #f3f3f3;
   width: max-content;
   padding: 11px;
   display: flex;
   float: left;
   margin-right: 10px;
 }
 .daterange {
   border: none;
   width: 180px;
 }
 input:focus {
   outline-offset: 0px;
   outline: -webkit-focus-ring-color auto 0px;
 }
 #search {
   margin-left: 30px;
   height: 2.3rem;
   margin: 0 0 0 30px;
   width: 560px;
   border: none;
 }
 .search {
   width: 614px;
   margin-right: -10px;
   padding: 5.7px 11px 5.7px 11px;
 }
 .search_icon {
   position: absolute; 
   margin-top: 5px;
 }
 .search .btn {
   background-color: #0033a1;
   text-transform: capitalize;
   padding: 0 2rem 0 1.6rem;
 }
 .modal {
   top: 20% !important;
 }
 #modal_filter-content .developer, #modal_filter-content .project, #modal_filter-content .utm, #modal_filter-content .payment {
   background-color: #fff;
   border: 1px solid #eeeeee;
   border-radius: 3px;
   margin-bottom: 10px;
 }
 .head-dev, .head-proj, .head-utm {
   border-bottom: 1px solid #eeee;
   padding-left: 20px;
 }
 .list-dev, .list-proj, .list-utm {
   padding-left: 56px;
 }
 .payment {
   height: 57px;
 }
 .payment-text {
   width: 54%;
   float: left;
   padding-left: 20px;
   font-weight: 600;
   font-size: 16px;
 }
 .payment-status {
   width: 46%;
   float: left;
 }
 .success {
   width: 50%;
   float: left;
 }
 .unsuccess {
   width: 50%;
   float: left;
 }
 .icon-active {
   transform: rotate(-180deg);
 }
 .daterangepicker select {
   display: unset;
   border-color: #000;
 }
 input:not([type]):focus:not([readonly]) {
   -webkit-box-shadow: none;
   box-shadow: none;
 }
 [type="checkbox"].checkbox-some:checked+label:before {
   border: 1px solid #fff;
   border-width: 1.5px 0 0 0;
   transform: unset;
   top: 40%;
   left: 7%;
 }
 .verify {
   height: 57px;
 }
 .verify-text {
   width: 54%;
   float: left;
   padding-left: 20px;
   font-weight: 600;
   font-size: 16px;
 }
 .verify-status {
   width: 46%;
   float: left;
 }
 .payment {
   height: 57px;
 }
 .payment-text {
   width: 54%;
   float: left;
   padding-left: 20px;
   font-weight: 600;
   font-size: 16px;
 }
 .payment-status {
   width: 46%;
   float: left;
 }