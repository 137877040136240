#master-project_type{
  & .content-add{
    cursor: pointer;
    // position:fixed;
    // text-align: center;
    right: 0;
    bottom: 0;
    float: right;
    border: 1px solid #003097;
    background-color: #003097;
    color: #fff;
    border-radius: 5px;
    padding: 0 20px;
    &.disable {
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
    p {
      display: flex;
      align-items: center;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  & #main_menu{
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    & div {
      cursor: pointer;
      padding: 10px 20px;
      margin: 5px 0px;
      border: 1px solid #333333;
      float: left;
      &.active {
        color: #ffffff;
        background-color: $main_color;
      }
    }
  }
  & .character-counter{
    margin-top: -10px;
  }
  & table {
    & #cursor_move{
      cursor: move;
      width: 60px;
      text-align: center;
    }
    & .add_new {
      & #cursor_move {
        cursor: default;
        & .cursor_move {
          display: none;
        }
      }
    }
    & #name {
      width: 25%;
    }
    & #code {
      width: 25%;
    }
    & #user {
      width: 5%;
    }
    & #priority {
      width: 7%;
    }
    & #tool {
      width: 19%;
    }
    & #tool,
    & #priority {
      & div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        & a {
          color: black;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          border-radius: 30px;
          &:hover{
            background-color: #e6e6e6;
          }
        }
      }
    }
    .submit-container {
      position: inherit;
      background-color: transparent;
    }
    .submit-container .btn-submit {
      color: #fff;
      background: #5a8eff;
      border: solid 1px #5a8eff;
    }
    .submit-container a.btn-discard {
      color: #1641b5 !important;
      border: solid 1px #1641b5 !important;
      border-radius: 5px !important;
    }
  }
}