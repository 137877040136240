#projectunit-view {
    .top-tool {
        display: flex;
        // padding: 25px;
        text-align: right;
        // background: #e6e6e6;
        // margin-top: 15px;
        // margin-right: 20px;
        /* margin-left: 80%; */
        justify-content: space-evenly;
    }
    .btn-excel {
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 15vw;
        z-index: 100;
    }
    .Import_Export-data {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #fff !important;
        border: 1px solid #0033a1 !important;
        border-radius: 3px;
        color: #0033a1;
        padding: 5px 8px 5px 5px;
        a.btn-floating {
            background-color: $theme-color;
            margin-right: 5px;
        }
        a.text {
            color: $theme-color;
            font-size: $font-size-md;
        }
    }
    .tb-unit {
        .row {
            margin-bottom: 0px;
            .input-field {
                margin-top: 5px;
                .select-wrapper input.select-dropdown,
                input[type=text]:not(.browser-default) {
                    height: 2rem;
                }
            }
        }
    }
    // ===== modal unit detail =====
    #modal_detail {
        width: 80vw;
        max-width: 80vw;
        min-width: 1000px;
    }
    .md-unit-name {
        font-size: $font-size-lg;
    }
    .md-text-head {
        font-size: 15px;
        margin-top: 6px;
    }
    .md-text-body {
        font-size: $font-size-md;
        text-align: right;
        top: 0px;
    }
    .modal {
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        background-color: #fff;
        padding: 0;
        max-height: 70%;
        width: 55%;
        margin: auto;
        overflow-y: auto;
        border-radius: 2px;
        will-change: top, opacity;
    }
    .modal .modal-content {
        padding: 0;
        margin-bottom: 0;
    }
    .original-detail {
        background-color: #fafafa;
        .input-field {
            margin-top: 5px;
            .select-wrapper input.select-dropdown,
            input[type=text]:not(.browser-default) {
                height: 2rem;
            }
        }
    }
    .select-wrapper input.select-dropdown {
        border-bottom: 1px solid #26a69a;
        -webkit-box-shadow: 0 1px 0 0 #26a69a;
        box-shadow: 0 1px 0 0 #26a69a;
    }
    .can-edit {
        border-bottom: 1px solid #26a69a;
        -webkit-box-shadow: 0 1px 0 0 #26a69a;
        box-shadow: 0 1px 0 0 #26a69a;
    }
    .readonly {
        // border-bottom: 1px solid #f7283a;
        // -webkit-box-shadow: 0 1px 0 0 #f7283a;
        // box-shadow: 0 1px 0 0 #f7283a;
    }
    .online-detail {
        background-color: #ededed;
        .input-field {
            margin-top: 5px;
            .select-wrapper input.select-dropdown,
            input[type=text]:not(.browser-default) {
                height: 2rem;
            }
        }
    }
    .unit-detail {
        .input-field {
            margin-top: 30px;
            .select-wrapper input.select-dropdown,
            input[type=text]:not(.browser-default) {
                height: 2rem;
            }
            .select-wrapper+label {
                top: -30px;
            }
        }
    }
    .unit-detail,
    .original-detail,
    .online-detail,
    .special-price-detail,
    .promotion-detail,
    .img-container {
        padding: 20px;
        margin-bottom: 0;
    }
    .promotion-detail {
        i {
            margin-right: 5px;
            color: $theme-color;
        }
    }
    [type="checkbox"]+label {
        height: 10px;
        line-height: 5px;
        display: unset;
        // align-items: center;
        // margin-bottom: 15px;
    }
    // ===== modal unit detail =====
    // ===== modal Catalog Feed =====
    .modal {
        &.modal-catalog-feed {
            & .catalog-feed {
                padding: 20px;
                &__header {
                    font-size: 20px;
                    font-weight: 500;
                }
                &__catalog {
                    display: flex;
                    justify-content: space-around;
                    flex-flow: column;
                    width: 100%;
                    &--link {
                        padding: 10px 0px;
                        word-wrap: break-word;
                        width: 100%;
                    }
                    &--button {
                        display: flex;
                        justify-content: space-evenly;
                        width: 100%;
                        &--copy {
                            & button {
                                border: 1px solid #003097;
                                background-color: #003097;
                                color: #fff;
                                border-radius: 5px;
                                padding: 10px 10px;
                            }
                        }
                        &--download {
                            border: 1px solid #003097;
                            background-color: #003097;
                            border-radius: 5px;
                            padding: 10px 10px;
                            cursor: pointer;
                            & a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        &.modal_img_crop {
            z-index: 2000 !important;
            & .imgage_crop {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                padding: 20px;
                overflow-x: hidden;
                div {}
                &__button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    div {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }
        }
    }
    // ===== modal Catalog Feed =====
    // ===== img zone =====
    .tabs .tab a:hover,
    .tabs .tab a.active {
        background-color: transparent;
        color: $theme-color;
    }
    .tabs .tab a {
        color: $theme-color;
    }
    .tabs .indicator {
        position: absolute;
        bottom: 0;
        height: 2px;
        background-color: $theme-color;
        will-change: left, right;
    }
    // ===== img zone =====
    .img-guild-container {
        margin: 20px 0;
    }
    .img-guild-head,
    .img-guild-body {
        font-weight: 500;
    }
    .img-guild-head {
        font-size: $font-size-lb;
    }
    .img-guild-body {
        font-size: $font-size-sm;
        color: #9e9e9e;
    }
    // ===== button upload image =====
    button.btn-upload-img {
        color: $theme-color;
        border: 1px solid $theme-color;
        background-color: transparent;
        padding: 10px 30px;
        border-radius: 4px;
    }
    input.input-upload-img {
        height: 2.8rem;
    }
    div.btn-cancel {
        height: 3rem;
        line-height: 3rem;
        a {
            color: #000;
            font-weight: 500;
        }
    }
    img.img-upload {
        width: 100px !important;
        margin-top: 10px;
        max-width: 100px;
        float: left;
        max-height: 100px;
    }
    .image_edit_status {
        position: absolute;
        bottom: -5px;
        left: 10px;
        background-color: #0033a1;
        border-radius: 5px;
        text-align: center;
        color: white;
        font-size: 13px;
        font-weight: 500;
        padding: 0px 10px;
    }
    // ===== button upload image =====
    a.btn-submit:not(button),
    .modal .modal-footer .btn {
        border: 1px solid #0033a1;
        background-color: transparent;
        color: #0033a1;
        box-shadow: 0 0 0 0;
        text-transform: capitalize;
    }
    .img-container {
        position: relative;
    }
    .tag {
        margin: 0 60px 30px 0 !important;
        width: 250px;
        display: flex;
    }
    .modal_content {
        margin: 7% 7%;
        overflow: scroll;
    }
    #modal_filter-content [type="checkbox"]+label {
        height: 23px;
    }
    #modal_filter-content .arrowDropdown {
        right: 10%;
        position: absolute;
    }
    [type="checkbox"].filled-in:not(:checked)+label:after {
        height: 19px;
        width: 19px;
        top: 2px;
        border-radius: 4px;
    }
    #modal_filter-content .head-building [type="checkbox"]+label,
    #modal_filter-content .head-floor [type="checkbox"]+label,
    #modal_filter-content .head-rt [type="checkbox"]+label,
    #modal_filter-content .head-ut [type="checkbox"]+label {
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }
    #modal_filter-content .list-building [type="checkbox"]+label,
    #modal_filter-content .list-floor [type="checkbox"]+label,
    #modal_filter-content .list-roomType [type="checkbox"]+label,
    #modal_filter-content .list-unitType [type="checkbox"]+label {
        color: #000;
    }
    #modal_filter-content [type="checkbox"].filled-in:checked+label:after {
        border: 2px solid #1673b3;
        background-color: #1673b3;
        height: 19px;
        width: 19px;
        top: 2px;
        border-radius: 4px;
    }
    [type="checkbox"].filled-in:checked+label:before {
        top: 2px;
    }
    .material-tooltip {
        max-width: 40%;
    }
    .filter_search {
        background-color: #fff;
        padding: 10px;
        height: 67px;
        // color: #0033a1;
    }
    img:not(.close) {
        width: 20px;
        margin-right: 10px;
    }
    .close {
        width: 11px;
    }
    .header {
        background-color: #f2f2f2;
        padding: 10px 0 17px 0;
        // color: #0b3857;
    }
    .filter {
        border: 1px solid #f3f3f3;
        width: max-content;
        padding: 11px;
        display: flex;
        float: left;
        margin-right: 10px;
    }
    .daterange {
        border: none;
        width: 180px;
    }
    input:focus {
        outline-offset: 0px;
        outline: -webkit-focus-ring-color auto 0px;
    }
    #search {
        margin-left: 30px;
        height: 2.3rem;
        margin: 0 0 0 30px;
        width: 100%;
        border: none;
    }
    .search {
        width: 85%;
        margin-right: -10px;
        padding: 5.7px 11px 5.7px 11px;
    }
    .search_icon {
        position: absolute;
        margin-top: 5px;
    }
    .btn:not(button) {
        background-color: #0033a1;
        text-transform: capitalize;
        padding: 0 2.6rem 0 1.6rem;
    }
    .modal {
        top: 20% !important;
    }
    .modal_filter-building,
    .modal_filter-floor,
    .modal_filter-room_type,
    .modal_filter-unit_type {
        background-color: #fff;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        margin-bottom: 10px;
    }
    /* .project {
      background-color: #fff;
      border: 1px solid #eeeeee;
      border-radius: 3px;
      margin-bottom: 10px;
    } */
    .head-building,
    .head-floor,
    .head-rt,
    .head-ut {
        border-bottom: 1px solid #eeee;
        padding-left: 20px;
    }
    .list-building,
    .list-floor,
    .list-roomType,
    .list-unitType {
        padding-left: 56px;
    }
    .payment {
        height: 57px;
    }
    .payment-text {
        width: 54%;
        float: left;
        padding-left: 20px;
        font-weight: 600;
        font-size: 16px;
    }
    .payment-status {
        width: 46%;
        float: left;
    }
    .success {
        width: 50%;
        float: left;
    }
    .unsuccess {
        width: 50%;
        float: left;
    }
    .icon-active {
        transform: rotate(-180deg);
    }
    .daterangepicker select {
        display: unset;
        border-color: #000;
    }
    input:not([type]):focus:not([readonly]) {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    [type="checkbox"].checkbox-some:checked+label:before {
        border: 1px solid #fff;
        border-width: 1.5px 0 0 0;
        transform: unset;
        top: 40%;
        left: 7%;
    }
    .gallery-table-list a {
        color: black;
    }
    #modal_view_promotion {
        h4 {
            padding-top: 20px !important;
        }
        h4,
        #show_all_promotion {
            padding: 0 20px;
        }
    }
    #modal_modify_promotion {
        h4 {
            padding: 20px 20px 0 20px !important;
        }
        #show_modify_promotion {
            padding: 0 10px;
        }
    }
    #modal_img_crop #cropbox {
        width: 80%;
    }
}