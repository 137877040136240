#master-facility{
  & .content-add {
    cursor: pointer;
    // position:fixed;
    // text-align: center;
    right: 0;
    bottom: 0;
    float: right;
    border: 1px solid #003097;
    background-color: #003097;
    color: #fff;
    border-radius: 5px;
    padding: 0 20px;
    &.disable{
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
    p {
      display: flex;
      align-items: center;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }

  & #main_menu{
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    & div.developer{
      cursor: pointer;
      padding: 10px 20px;
      margin: 5px 0px;
      border: 1px solid $theme-color;
      float: left;
      &.active{
        color: #ffffff;
        background-color: $main_color;
      }
      .edit-facility {
        vertical-align: middle;
        margin-left: 15px;
      }
    }
  }

  & table {
    & #cursor_move {
      cursor: move;
      width: 60px;
      text-align: center;
    }
    & .add_new {
      & #cursor_move {
        cursor: default;

        & .cursor_move {
          display: none;
        }
      }
    }
    & #description {
      width: 30%;
    }
    & #imgpre {
      width: 10%;
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        & img {
          max-height: 60px;
          max-width: 100%;
        }
      }
    }
    & #browse {
      width: 35%;
      & button {
        width: 30%;
        height: 45px;
        border-radius: 5px;
        border: 1px solid;
        color: $main_color;
        font-size: 13px;
        background-color: transparent;
        z-index: 0;
      }
      & input {
        margin-bottom: 0px;
        margin-left: 20px;
        width: 50%;
      }
    }
    & #user {
      width: 5%;
    }
    & #priority {
      width: 7%;
    }
    & #tool {
      width: 19%;
    }
    & #tool,
    & #priority {
      & div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        & a {
          color: black;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          border-radius: 30px;
          &:hover{
            background-color: #e6e6e6;
          }
        }
      }
    }
    .submit-container {
      position: inherit;
      background-color: transparent;
    }
    .submit-container .btn-submit {
      color: #fff;
      background: #5a8eff;
      border: solid 1px #5a8eff;
    }
    .submit-container a.btn-discard {
      color: #1641b5 !important;
      border: solid 1px #1641b5 !important;
      border-radius: 5px !important;
    }
  }
}