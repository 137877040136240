// @import "layout/left";
// @import "layout/top";
// @import "layout/sidebar";
// @import "layout/content";
// @import "layout/menu_left";
// @import "layout/content-standalone";
// sena
// $theme-color: #25BDAD;
// kpn
$theme-color: #0033a1;
$font-size-sm: 10px;
$font-size-lb: 13px;
$font-size-md: 18px;
$font-size-lg: 30px;
.main-header-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .main-header-title {
        font-size: 30px;
        font-weight: 500;
        line-height: 2.2;
    }
}

.dropdown-content li>a,
.dropdown-content li>span {
    color: $theme-color;
}

[type="checkbox"]:checked+label:before {
    border-right: 2px solid $theme-color;
    border-bottom: 2px solid $theme-color;
}

// checkbox
[type="checkbox"].filled-in:checked+label:after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid $theme-color;
    background-color: $theme-color;
    z-index: 0;
}

textarea.materialize-textarea {
    padding: .8rem 0 0 0;
}


/*
    Util
*/

@import "util/global";
@import "authen/view";
@import "menu/top";
@import "menu/top-setting";
@import "menu/top-project";
@import "menu/left";
@import "developerDetail/view";
@import "projectAll/view";
@import "projectGeneral/form";
@import "projectDetail/form";
@import "projectFacility/form";
@import "projectBuilding/form";
@import "projectRoomtype/form";
@import "projectUnit/form";
@import "settingFacility/form";
@import "settingRoomtype/form";
@import "systemUser/form";
@import "systemUser/modal_assign";
// ---------------- New Scss ----------------
$main_color: #0033a1;
[type="checkbox"]+label:before,
[type="checkbox"]:not(.filled-in)+label:after {
    top: -10px;
}

[type="checkbox"]:checked+label:before {
    top: -15px;
}

a {
    color: $main_color;
}

@import "new/main";
@import "new/pages/banner";
@import "new/pages/master_data/master_data";
@import "new/pages/master_data/master_facility";
@import "new/pages/master_data/master_feature";
@import "new/pages/master_data/master_metro";
@import "new/pages/master_data/master_location";
@import "new/pages/master_data/master_project_type";
@import "new/pages/master_data/master_roomtype";
@import "new/pages/master_data/master_tag_keyword";
@import "new/pages/master_data/master_time_slot";
@import "new/pages/daily_deal";
@import "new/pages/datatable";