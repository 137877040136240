#projectgeneral-view {
	.content-head {
		font-size: $font-size-md;
		font-weight: 500;	
	}

	.content-sub-head {
		font-size: $font-size-lb;
		font-weight: 500;
	}


	// ===== button upload image =====
	button#ckfinder-modal-logo {
	    color: $theme-color;
	    border: 1px solid $theme-color;
	    background-color: transparent;
			padding: 10px 0;
			border-radius: 4px;
	}

	input#ckfinder-input-logo {
	    height: 2.8rem;
	}

	div#cancel {
	    height: 3rem;
	    line-height: 3rem;
	    a {
	    	color: #000;
	    	font-weight: 500;
	    }
	}

	img#ckfinder-img-preview-logo {
	    width: 60px;
	    margin-top: 7px;
	    max-width: 60px;
	    float: left;
	    max-height: 60px;
	}

	.img-guild-head, .img-guild-body {
		font-weight: 500;
	}

	.img-guild-head {
		font-size: $font-size-lb;
	}

	.img-guild-body {
		font-size: $font-size-sm;
		color: #9e9e9e;
	}
	// ===== button upload image =====
}