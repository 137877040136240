#setting-roomtype {
    .collection .collection-item.active {
        background-color: $theme-color;
        color: #eafaf9;
    }

    .collection a.collection-item {
        display: block;
        -webkit-transition: .25s;
        transition: .25s;
        color: $theme-color;
    }
}