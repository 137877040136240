#developer-view{
  margin-top: 7%;
  .preventClick{
    pointer-events: none;
  }
  input[readonly]{
    cursor: default;
  }
  .rowMargin{
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .rowBtnPadding{
    margin-bottom: 0; 
    padding: 0px 0 10px;
  }
  .collapsible {
    margin: 0;
    padding: 0;
  }
  .collapsible-body {
    padding: 10px 20px;
  }
  .collapsible-header {
    display: flex;
    align-items: center;
    border-bottom: none;
    width: 90%;
    float: left;
    padding: 1rem 1rem 1rem 1.4rem;
  }
  .bdivndTagCode {
    // width: 10%;
    float: right;
    margin-right: 6px;
  }
  // .content-comp .bdivndTagCode, .content-brand .bdivndTagCode {
  //   margin-right: 0;
  // }
  // .content-brand .bdivndTagCode {
  //   margin-right: 0;
  // }
  .div-logo {
    // margin-right: 20px;
    display: contents;
  }
  .imageLogo {
    max-width: 100px;
    max-height: 50px;
    width: 100%;;
  }
  .edit-icon {
    width: 18px;
  }
  .zoneName {
    padding-left: 25px;
    width: 90%;
    display: flex;
    align-items: center;
    & .btnAdd {
      padding: 0 15px;
      margin-left: 15px;
      i {
        margin-right: 0;
      }
    }
  }
  .zoneStatus{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .status{
      padding: 0px 5px;
    }
  }
  .blindTagCode {
    color: #a9a9a9;
    width: 120px;
    text-align: right;
  }
  .ckfinder-img-preview-logo{
    float: left;
    width: 70%;
  }
  button{
    &.ckfinder {
      color: #0a3957;
      border: 2px solid #0a3957;
      background-color: transparent;
      padding: 10px 0;
      width: 100%;
      border-radius: 20px;
      line-height: 30px;
      &[disabled] {
        color: rgba(0, 0, 0, 0.42);
      }
      &:focus {
        background-color: #0a3957;
        color: #dededede;
      }
    }
  }
  .cancel{
    line-height: 50px;
    text-transform: capitalize;
    color: red;
  }
  .btn{
    &.btn{
      &Status,
      &Add,
      &Update,
      &Close {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
      }
      &Status {
        &.status{
          &Show{
            border: 2px solid #ffa726;
            background-color: #ffa726;
          }
          &Hide{
            border: 2px solid #ffa726;
            background-color: #ffa726;
          }
          &Delete{
            border: 2px solid #f44336;
            background-color: #f44336;
            color: #fff;
          }
        }
      }
      &Add{
        border: 2px solid #0a3957;
        background-color: #fff;
        color: #0a3957;
      }
      &Update{
        border: 2px solid #0a3957;
        background-color: #0a3957;
        color: #fff;
        & .preloader-wrapper {
          width: 20px;
          height: 20px;
          & .spinner-layer{
            border-color: #fff;
          }
        }
      }
      &Close{
        border: 2px solid #f44336;
        background-color: #f44336;
        color: #fff;
      }
    }
  }

  .col {
    // border: 1px solid #e3e3e3;
    padding: 0;
  }

  .head:not(.modal .head) {
    background-color: $theme-color;
    color: #fff;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 20px;
  }
  .head .title {
    font-size: 20px;
  }
  .developer .head {
    border-top-left-radius: 5px;
  }
  .company .head {
    background-color: #1340a7;
  }
  .brand .head {
    border-top-right-radius: 5px;
  }
  .list-developer {
    display: flex;
    align-items: center;
  }
  .nothing {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .list-developer div p.title {
    font-size: 18px;
    color: #2b96d4;
  }
  .list-developer div p.detail {
    font-size: 13px;
    color: #959595;
  }
  & .content-add {
    cursor: pointer;
    right: 0;
    bottom: 0;
    float: right;
    border: 1px solid #2b96d4;
    background-color: #2b96d4;
    color: #fff;
    border-radius: 5px;
    padding: 0 20px;
    margin-right: 16px;
    &.disable{
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
    p {
      display: flex;
      align-items: center;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
    }
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
  .col.s4.developer {
    border-top-left-radius: 5px;
    box-shadow: -4px 1px 7px 2px rgba(234,234,234,0.5);
  }
  .col.s4.company {
    box-shadow: 0px 4px 7px -2px rgba(234,234,234,0.5);
  }
  .col.s4.brand {
    border-top-right-radius: 5px;
    box-shadow: 4px 1px 7px 2px rgba(234,234,234,0.5);
  }
  .content-developer {
    height: 57vh;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    overflow: scroll;
  }
  .content-company {
    height: 57vh;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    overflow: scroll;
  }
  .content-brand {
    height: 57vh;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    overflow: scroll;
  }
  .modal .head, .modal .content {
    padding: 0 50px;
  }
  .modal .head {
    height: 110px;
  }
  .modal .head .p-head {
    color: #2b96d4;
    font-size: 28px;
    margin: 20px 0 0 0;
  }
  .modal .head .p-info {
    color: #fff;
    font-size: 15px;
    margin: 0;
  }
  .modal .content {
    padding: 40px 50px;
  }
  .input-field{
    padding: 0 30px 0 0;
  }
  input[type=text]:not(.browser-default) {
    margin: 0 0 20px 12px;
  }

  .modal .dev.submit-container {
    position: relative;
    margin: 0 70px 30px 0px;
    background-color: transparent;
  }
  .modal .comp.submit-container {
    position: relative;
    margin: 0 30px 30px 0px;
    background-color: transparent;
  }
  .modal .brand-add.submit-container {
    position: relative;
    margin: 0 25px 30px 0px;
    background-color: transparent;
  }
  .modal .brand-edit.submit-container {
    position: relative;
    margin: 0 75px 30px 0px;
    background-color: transparent;
  }
  .modal .submit-container .btn-submit {
      color: #fff;
      background: #5a8eff;
      border: solid 1px #5a8eff;
  }
  .modal .submit-container a.btn-discard {
      color: #1641b5 !important;
      border: solid 1px #1641b5 !important;
      border-radius: 5px !important;
      padding: 8px 10px;
  }
  .modal .submit-container .btn-submit {
      color: #fff;
  }
  .modal .footer-btn {
    display: flex;
    align-items: center;
    padding-right: 8px;
    position: absolute;
    bottom: 0;
  }
  .modal a {
    color: #7f7f7f;
  } 
  .modal .title {
    position: absolute;
    top: 0px;
  }
  .modal .dev.footer-btn .col.s4 {
    margin-left: 0;
    padding: 0;
    margin: 0 0px 30px 13px;
  }
  .modal .comp.footer-btn .col.s4 {
    margin-left: 0;
    padding: 0;
    margin: 0 0px 30px 63px;
  }
  .modal .brand.footer-btn .col.s4 {
    margin-left: 0;
    padding: 0;
    margin: 0 0px 30px 23px;
  }
  .modal .close {
    top: 20px;
    position: absolute;
    display: flex;
    right: 20px;
  }
  .modal .close-icon {
    width: 15px;
  }
  .content-add.disable {
    opacity: 0.5;
    filter: unset;
  }
  .click-active {
    background-color: #e1f5fe !important;
  }

}