#system-user {
    .new{
        text-align: center;
        right: 0;
        bottom: 0;
        float: right;
        border: 1px solid #003097;
        background-color: #003097;
        color: #fff !important;
        border-radius: 5px;
        padding: 0 20px;
        width: 200px;
        // padding-bottom: 15px;
    }
    .new img {
        width: 20px;
    }
    .new a {
        color: #fff !important;
    }
    .btn-floating {
        background-color: #003097;line-height: 50px;
    }
    span.badge.new {
        background-color: $theme-color;
    }

    .btn-submit {
        border: 1px solid $theme-color;
        background-color: transparent;
        color: $theme-color;
        box-shadow: 0 0 0 0;
        text-transform: capitalize;
    }

    .btn-discard {
        color: #000;
        font-weight: 500;
        margin-right: 20px;
    }

    td a {
        color: #000;
    }
    td.setting {
        text-align: center;
    }
}