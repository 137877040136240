#system-user #modal_assign{
	& .zone_select div {
		/* margin-bottom: 15px; */
	  margin-bottom: -6px;
	}
	& [type="radio"], [type="checkbox"]{
		&+label.active,
		&+label.set_active {
		  background-color: #0a395744;
		}
		&:disabled+label {
			cursor: not-allowed;
		}
		&+label{
			text-overflow: ellipsis;
			white-space: nowrap;
    	overflow: hidden;
			color: #000;
			border-radius: unset !important;
			margin: unset;
			height: 35px;
			line-height: 35px;
			width: 100%;
			padding: 0px 10px;
			text-align: center;
			&:before{
				border-radius: unset !important;
				margin: unset;
			}
			&:after{
				border-radius: unset !important;
				margin: unset;
    		overflow: unset;
			}
		}
		&:checked+label{
			color: #fff;
			border-radius: unset !important;
			margin: unset;
			z-index: 5;
			&:before{
				border-radius: unset !important;
				margin: unset;
			}
			&:after{
				border-radius: unset !important;
				margin: unset;
				box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
			}
		}
		&.filled-in{
			&:not(:checked)+label{
				&:before{
					top: 0px;
					width: 100%;
					height: 100%;
					left: 0px;
					border: 2px solid #dedede;
					transform: unset;
					transform-origin: unset;
					z-index: -1;
					background-color: #ebebeb;
				}
				&:after{
					top: 0px;
					height: 100%;
					width: 100%;
					border: 2px solid #dedede;
					z-index: -1;
					background-color: #ebebeb;
				}
			}
			&:checked+label{
				&:before{
					top: 0;
					width: 100%;
					height: 100%;
					left: 0px;
					border-top: unset;
					border-left: unset;
					border-right: unset;
					border-bottom: unset;
					transform: unset;
					transform-origin: unset;
					z-index: -1;
				}
				&:after{
					top: 0;
					width: 100%;
					height: 100%;
					border: 2px solid $theme-color;
					background-color: $theme-color;
					z-index: -1;
					box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
				}
			}
		}
	}

}