#pages-banner{

  & .container {
    margin-top: 40px;
    width: 85%;
  }
  
  & .content-add {
    cursor: pointer;
    // position:fixed;
    // text-align: center;
    right: 0;
    bottom: 0;
    float: right;
    border: 1px solid #003097;
    background-color: #003097;
    color: #fff;
    border-radius: 5px;
    padding: 0 20px;
    &.disable{
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
    p {
      display: flex;
      align-items: center;
    }
    img {
      width: 20px;
      margin-right: 10px;
      background-color: unset;
    }
  }

  & img {
    /*margin: 15px 0px;*/
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  
  & .top-tool {
    display: block;
    position: absolute;
    padding: 10px;
    text-align: center;
    right: 1%;
    bottom: 0;
    line-height: 0px;
  }
  
  & table {
    // margin-left: 30px;
    & th{
      &#tool {
        text-align: right;
        padding: 2px 25px;
        & a i {
          color: black;
        }
      }
    }
    & tbody{
      & tr{ height: 150px; }
    }
    & td{
      &#imgpre {
        width: 20%;
        text-align: center;
      }
      &#browse {
        width: 40%;
        text-align: center;
      }
      &#device {
        width: 15%;
        text-align: center;
      }
      &#user {
        width: 15%;
        text-align: center;
      }
      &#priority {
        & div {
          display: flex;
          justify-content: space-evenly;
        }
        & div a {
          color: black;
          cursor: pointer;
        }
      }
      &#tool {
        width: 15%;
        & a {
          color: black;
          display: flex;
          justify-content: flex-end;
          margin-right: 10px;
        }
        & ul li a {
          color: black;
          display: flex;
          border: 1px solid;
          font-size: 14px;
          margin-right: 0;
          justify-content: flex-start;
        }
        & div {
          display: flex;
          justify-content: space-evenly;
        }
        & div a {
          color: black;
          cursor: pointer;
        }
      }
    }
  }
  


  & #browse{
    & button {
      width: 30%;
      height: 45px;
      border-radius: 5px;
      border: 1px solid;
      color: $theme-color;
      font-size: 13px;
      background-color: transparent;
      z-index: 0;
    }
    & input {
      margin-bottom: 0px;
      margin-left: 20px;
      width: 50%;
    }
  }

  & p span {
    color: gray;
    font-size: 12px;
  }

  & input[type=text].datepicker {
    width: 40%;
  }

  & label {
    color: black;
  }

  & .dropdown-content li>a, & .dropdown-content li>span {
    font-weight: 400;
  }

  #main_menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .banner_type {
      padding: 10px;
      border: 1px solid $theme-color;
      cursor: pointer;
      &.active {
        background-color: $main_color;
        color: #fff;
      }
    }
  }
  .btn-floating {
    background-color: $theme-color;
  }

}