#projectbuilding-view {
    .top-tool {
        display: flex;
        // padding: 25px;
        // text-align: right;
        // background: #e6e6e6;
        // margin-top: 15px;
        // margin-right: 20px;
        /* margin-left: 80%; */
        justify-content: space-evenly;
    }
    .btn-excel {
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 15vw;
        z-index: 100;
    }
    .Import_Export-data {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #fff !important;
        border: 1px solid #0033a1 !important;
        border-radius: 3px;
        color: #0033a1;
        padding: 5px 8px 5px 5px;
        a.btn-floating {
            background-color: $theme-color;
            margin-right: 5px;
        }
        a.text {
            color: $theme-color;
            font-size: $font-size-md;
        }
    }
    // ===== button tab =====
    .collection .collection-item.active {
        color: white;
        background-color: $theme-color;
        border: 1px solid $theme-color;
    }
    .collection {
        width: 100%;
        display: inline-block;
        border: 0px solid #fff;
    }
    .collection-item {
        float: left;
    }
    .collection .collection-item {
        border: 1px solid #000;
    }
    .collection a.collection-item {
        background-color: white;
        color: black;
        display: flex;
        justify-content: space-around;
    }
    .collection .collection-item:last-child {
        border-bottom: 1px solid black;
    }
    .collection a.collection-item span {
        margin-top: 10px;
    }
    // ===== button tab =====
}