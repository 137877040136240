#projectfacility-view {
    .content-add {
        cursor: pointer;
        // position:fixed;
        // text-align: center;
        right: 0;
        bottom: 0;
        float: right;
        border: 1px solid #003097;
        background-color: #003097;
        color: #fff;
        border-radius: 5px;
        padding: 0 20px 15px 20px;
        cursor: pointer;
        &.disable {
            cursor: not-allowed;
            pointer-events: none;
            filter: grayscale(1);
        }
        p {
            display: flex;
            align-items: center;
            font-size: 12px;
        }
        img {
            width: 20px;
            margin-right: 10px;
        }
    }
    .content-head {
        font-size: $font-size-md;
        font-weight: 500;
    }
    .content-sub-head {
        font-size: $font-size-lb;
        font-weight: 500;
    }
    [type="checkbox"].filled-in:checked+label:after {
        border: 2px solid $theme-color;
        background-color: $theme-color;
    }
}