#setting-facility {
    .div-act {
        background-color: $theme-color;
        padding: 1% 0 !important;
        text-align: center;
        color: #fff;
        pointer-events: none;
        border-left: 1px solid white;
    }
    .container .row {
        margin: 0;
    }
    .div-deact {
        background-color: #fff;
        padding: 1% 0 !important;
        text-align: center;
        cursor: pointer;
        /*border-right: 1px solid white;*/
        border-left: 1px solid white;
    }
    .div-deact:hover {
        background-color: rgba(0, 0, 0, 0.2);
    }
    .dropdown-content {
        width: 140px !important;
    }
    .dropdown-content li>a,
    .dropdown-content li>span {
        font-size: 16px;
        color: #222;
        display: block;
        line-height: 22px;
        padding: 14px 16px;
    }
    #move {
        padding: 18px 15px 15px 15px;
        /*padding: 15px;*/
        background: #fafafa;
        border-bottom: 3px solid white;
        margin-bottom: 0px;
    }
    .name {
        margin-top: 18px;
        cursor: pointer;
    }
    #tool {
        text-align: right;
        margin-top: 18px;
    }
    #tool a {
        color: black;
    }
    #browseBTN button {
        width: 6vw;
        height: 45px;
        border-radius: 5px;
        border: 1px solid;
        color: #0e7ccb;
        font-size: 13px;
        background-color: transparent;
        z-index: 0;
    }
    #text_modal {
        margin: 0 0 3% 2%;
    }
    #form_modal {
        margin: 0 2%;
    }
    .modal {
        max-height: 75%;
    }
}