#toast-container {
  & .toast {
    max-width: 300px;
    color: #fff;
    width: 100%;
    
    &.success {
      background-color: #73b374;
    }
    &.fail {
      background-color: #d05e5e;
    }
    &.warning {
      background-color: #ffb818;
    }
    & #statusToast {
      text-align: center;
      width: 100%;
    }
  }
}

#window_loader {
  & .loader_zone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: #00000099;
    z-index: 9999999;
  }
}

.chip .close {
  width: unset;
}