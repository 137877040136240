#projectall-view {
    .center {
        text-align: center;
    }
    .Import_Export-data {
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #fff !important;
        border: 1px solid #0033a1 !important;
        border-radius: 3px;
        color: #0033a1;
        padding: 5px 8px 5px 5px;
        a.btn-floating {
            background-color: $theme-color;
            margin-right: 5px;
        }
        a.text {
            color: $theme-color;
            font-size: $font-size-md;
        }
    }
    .img-project {
        width: 40px;
        max-width: 40px;
        max-height: 40px;
    }
    .project-code,
    .project-name {
        color: #000;
    }
    .project-code,
    .unit-output {
        font-size: $font-size-sm;
    }
    th,
    td {
        font-weight: 500;
    }
    td {
        font-size: $font-size-md;
        a {
            color: #0033a1;
        }
    }
    .project-progress-container {
        display: flex;
        align-items: center;
    }
    a.dropdown-button.action-all,
    .action-container a {
        color: #000;
    }
    .top-tool {
        display: flex;
        // padding: 25px;
        text-align: right;
        // background: #e6e6e6;
        margin-top: 15px;
        // margin-right: 20px;
        /* margin-left: 80%; */
        justify-content: space-evenly;
    }
    .Import_Export-data {
        display: flex;
        align-items: center;
        a.btn-floating {
            background-color: $theme-color;
            margin-right: 5px;
        }
        a.text {
            color: $theme-color;
            font-size: $font-size-md;
        }
    }
    // ===== modal Catalog Feed =====
    .modal {
        &.modal-catalog-feed {
            & .catalog-feed {
                padding: 20px;
                &__header {
                    font-size: 20px;
                    font-weight: 500;
                }
                &__catalog {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    width: 100%;
                    &--link {
                        padding: 10px 0px;
                        word-wrap: break-word;
                        width: 100%;
                    }
                    &--button {
                        display: flex;
                        justify-content: space-evenly;
                        width: 100%;
                        &--copy {
                            & button {
                                border: 1px solid #003097;
                                background-color: #003097;
                                color: #fff;
                                border-radius: 5px;
                                padding: 10px 10px;
                            }
                        }
                        &--download {
                            border: 1px solid #003097;
                            background-color: #003097;
                            border-radius: 5px;
                            padding: 10px 10px;
                            cursor: pointer;
                            & a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    // ===== modal Catalog Feed =====
}