#menu-left {
	.side-nav {
			background-color: $theme-color;
	}
	.sub-menu2 a{
		// padding: 0 0px 0 21px;
		padding-left: 75px !important;
	}
	.header {
		height: 64px;
		background-color: #002b87;
		color : #fff !important;
		padding: 0px 20px 0 20px;
    vertical-align: middle;
	}
	.logo {
		display: inline-block;
		margin-top: 9px;
		img {
			width: 100px;
		}
	}
	.berger {
		float: right;
		margin-top: 18px;
	}
	.main img {
		width: 20px;
		margin: 0px 20px 0 0px;
		display: inline-block;
	}
	.main div {
		display: inline-block;
	}
	.main {
		.caret {
			position: absolute;
    	right: 0;
		}
		.sub-caret {
			width: 11px;
			right: 4px;
		}
	}
	.collapsible-body {
		width: 100%;
	}
	.list {
		background-color: $theme-color;
	}
	.side-nav li>a {
		color : #fff !important;
		display: flex;
    align-items: center;
	}
	.side-nav ul.list-item li a {
		color : $theme-color !important;
		padding-left: 55px;
	}
	.side-nav .collapsible-body>ul:not(.collapsible)>li.active a, .side-nav.fixed .collapsible-body>ul:not(.collapsible)>li.active a {
		color: #002b87;
		background-color: #eeeeee;
	}
	.side-nav li.list.active {
		background-color : #2b96d4 !important;
	}
	.side-nav .collapsible-body>ul:not(.collapsible)>li.active, .side-nav.fixed .collapsible-body>ul:not(.collapsible)>li.active {
    background-color: #fff;
	}
  .icon-active {
    transform: rotate(-180deg);
	}
}