
#authen-view {
  .main-content {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    background-image: url("../../Images/Backend_Login_Realdeal.jpg");
    // background-repeat: no-repeat;
    background-size: cover;
  }
  .main-content .top-logo {
    width: 130px;
    top: 20px;
    left: 20px;
    position: absolute;
  }
  .center {
    margin: auto;
  }
  .center .signin {
    color: #fff;
  }
  .center .signin .p-signin {
    font-size: 36px;
    font-weight: 600;
  }
  .center .signin .p-access {
    font-size: 15px;
    margin: -12px 0 20px 0;
  }
  input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 0px 15px;
    width: calc(100% - 30px);
  }
  ::placeholder {
    color: #b2b2b2;
  }
  .btn{
    background-color: #2b96d4;
    text-transform: none;
    width: 100%;
    border-radius: 5px;
    height: 3rem;
  }
  .btn:hover {
    background-color: #2b96d4;
  }
  .btn:active {
    background-color: #2b96d4;
  }
}
